import { Box, Text } from '@chakra-ui/react';

const AuditLogInfo = ({ auditLogs }) => (
  <Box mt={4} backgroundColor='gray.100' p={3} borderRadius='md' border='1px solid' borderColor='gray.200'>
    <Text>
      <Text as='span' color='gray.500' mr={2}>
        Entity ID:
      </Text>
      {auditLogs?.entity_id}
    </Text>
    <Text>
      <Text as='span' color='gray.500' mr={2}>
        Entity Name:
      </Text>
      {auditLogs?.entity_name}
    </Text>
    <Text>
      <Text as='span' color='gray.500' mr={2}>
        Entity Risk Level Category:
      </Text>
      <Text as='span' textTransform='capitalize'>
        {auditLogs?.risk_level}
      </Text>
    </Text>
  </Box>
);

export default AuditLogInfo;
