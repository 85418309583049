import { CalendarIcon, EmailIcon } from '@chakra-ui/icons';
import { Box, Heading, HStack, Icon, Stack, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { formatDateTime } from 'utils/utils';

const AuditLogCard = ({ auditLogId, date, email, resources }) => {
  const cardBg = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const labelColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Box
      borderRadius='md'
      backgroundColor={cardBg}
      border='1px solid'
      borderColor={borderColor}
      p={6}
      shadow='sm'
      _hover={{ shadow: 'md', transform: 'scale(1.02)' }}
      transition='all 0.2s ease-in-out'
      w={'full'}
    >
      <VStack align='start' spacing={5} w='full'>
        <Box>
          <Heading size='xs' color={labelColor} textTransform='uppercase'>
            Audit Log ID
          </Heading>
          <Text fontSize='md' fontWeight='bold' width='full' wordBreak='break-word' whiteSpace='normal'>
            {auditLogId}
          </Text>
        </Box>

        <Stack justifyContent='space-between' w='full' flexWrap='wrap' direction={'column'}>
          <Box>
            <HStack spacing={2}>
              <Icon as={CalendarIcon} color='blue.500' />
              <Heading size='xs' color={labelColor} textTransform='uppercase'>
                Date
              </Heading>
            </HStack>
            <Text fontSize='md'>{formatDateTime(date)}</Text>
          </Box>

          <Box>
            <HStack spacing={2}>
              <Icon as={EmailIcon} color='blue.500' />
              <Heading size='xs' color={labelColor} textTransform='uppercase'>
                Email
              </Heading>
            </HStack>
            <Text fontSize='md'>{email}</Text>
          </Box>
        </Stack>

        <VStack spacing={1}>
          {resources?.map(({ metadata, id }) => (
            <Stack spacing={1} w='full' key={id}>
              {metadata?.notes && (
                <Text wordBreak='break-word' whiteSpace='normal' w='full'>
                  <Text as='span' color={labelColor} mr={2}>
                    Notes:
                  </Text>
                  {metadata?.notes}
                </Text>
              )}
              {metadata?.supporting_document_identifier && (
                <Text width='full' wordBreak='break-word' whiteSpace='normal'>
                  <Text as='span' color={labelColor} mr={2} whiteSpace={'nowrap'}>
                    Document Identifier:
                  </Text>
                  {metadata?.supporting_document_identifier}
                </Text>
              )}
              {metadata?.risk_level?.new_risk_level && (
                <Text>
                  <Text as='span' color={labelColor} mr={2}>
                    Risk Level:
                  </Text>
                  <Text as={'span'} textTransform='capitalize'>
                    {metadata?.risk_level?.old_risk_level ? metadata?.risk_level?.old_risk_level : 'none'} to{' '}
                    {metadata?.risk_level?.new_risk_level}
                  </Text>
                </Text>
              )}
            </Stack>
          ))}
        </VStack>
      </VStack>
    </Box>
  );
};

export default AuditLogCard;
