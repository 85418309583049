import React, { useContext, useEffect, useState } from 'react';
import { BsLayers } from 'react-icons/bs';
import Select from 'react-select';
import { AddIcon, ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  IconButton,
  Select as ChakraSelect,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { getRolesResourceFilteredByMerchant } from 'api/policy';
import { useAuthentication } from 'AuthContext';
import { ACCESS_SCOPES, PERMISSION_NAMES } from 'constants/permissions_v2';
import { APPROVER_TYPES, POLICY_CONFIGURATIONS_FIELDS } from 'modules/policies/constants/constants';
import { PolicyContext } from 'modules/policies/CreatePolicy';
import { showError } from 'utils/notifications';

const AddApprovers = () => {
  const {
    amount,
    approvalFlow,
    updateApprover,
    addApproverSequence,
    removeApproverSequence,
    addStep,
    removeStep,
    goToPreviousStep,
    submitPolicy,
    currentStep,
    isPolicyCreationLoading,
  } = useContext(PolicyContext);

  const { user } = useAuthentication();

  const [approverOptions, setApproverOptions] = useState([]);
  const [isCreatePolicyDisabled, setIsCreatePolicyDisabled] = useState(true);

  const toast = useToast();

  useEffect(() => {
    fetchApprovers();
  }, []);

  useEffect(() => {
    validateApprovers();
  }, [approvalFlow]);

  const fetchApprovers = async () => {
    try {
      const response = await getRolesResourceFilteredByMerchant(
        PERMISSION_NAMES.BANKING_PAYOUT_APPROVE,
        ACCESS_SCOPES.MERCHANTS,
        user?.merchant_id
      );
      const options = transformApproverData(response?.data?.data);

      setApproverOptions(options);
    } catch (error) {
      showError(toast, 'Error fetching eligible approvers', error);
    }
  };

  const transformApproverData = (data) => {
    const roleOptions = data?.map((role) => ({
      label: role?.name,
      value: role?.id,
      type: APPROVER_TYPES.ROLE,
      options: role?.users?.map((user) => ({
        label: `${user?.first_name} ${user?.last_name}`,
        value: user?.id,
        type: APPROVER_TYPES.ROMA_OPS_DASHBOARD_USER,
        email: user?.email,
      })),
    }));

    const allUserOptions = data
      .flatMap((role) => role?.users)
      .map((user) => ({
        label: `${user?.first_name} ${user?.last_name}`,
        value: user?.id,
        type: APPROVER_TYPES.ROMA_OPS_DASHBOARD_USER,
        email: user?.email,
      }));

    return [
      {
        label: 'Roles',
        options: roleOptions,
      },
      {
        label: 'Individuals',
        options: allUserOptions,
      },
    ];
  };

  const handleApproverChange = (selectedOptions, levelIndex, subLevelIndex) => {
    const approvers = selectedOptions?.map((option) => ({
      id: option?.value,
      type: option?.type,
    }));

    updateApprover(levelIndex, subLevelIndex, POLICY_CONFIGURATIONS_FIELDS.APPROVERS, approvers);
    validateApprovers();
  };

  const validateApprovers = () => {
    const isValid = approvalFlow?.levels?.every((level) =>
      level?.sub_levels?.every((subLevel) => subLevel?.approvers?.length > 0)
    );

    setIsCreatePolicyDisabled(!isValid);
  };

  return (
    <Box maxWidth='600px' margin='auto' p={5}>
      <VStack spacing={6} align='stretch'>
        <Text fontWeight='bold' fontSize='xs' color='gray'>
          STEP {currentStep} / 2
        </Text>
        <Heading size='md'>Add approvers for amount higher than ${amount}</Heading>
        <Text fontSize='sm' color='gray.600'>
          Follow steps (top to bottom)
        </Text>

        {approvalFlow?.levels?.map((level, levelIndex) => (
          <Box key={levelIndex} position='relative' mb={6}>
            <Box borderWidth={1} borderRadius='md' p={4}>
              <Flex align='center' mb={4}>
                <Box
                  bg='black'
                  color='white'
                  w={6}
                  h={6}
                  borderRadius='full'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  mr={3}
                >
                  {levelIndex + 1}
                </Box>
              </Flex>

              {level?.sub_levels?.map((subLevel, subLevelIndex) => (
                <Box key={`${levelIndex}-${subLevelIndex}`} mb={4}>
                  <Flex align='center' mb={2}>
                    <ChakraSelect
                      value={subLevel?.quorum}
                      onChange={(e) =>
                        updateApprover(levelIndex, subLevelIndex, POLICY_CONFIGURATIONS_FIELDS.QUORUM, e.target.value)
                      }
                      w='120px'
                      mr={2}
                      icon={<ChevronDownIcon />}
                    >
                      <option value={POLICY_CONFIGURATIONS_FIELDS.QUORUM_ONE}>Any of</option>
                      <option value={POLICY_CONFIGURATIONS_FIELDS.QUORUM_ALL}>All of</option>
                    </ChakraSelect>
                    <Box flex={1}>
                      <Select
                        isMulti
                        options={approverOptions}
                        onChange={(selectedOptions) => handleApproverChange(selectedOptions, levelIndex, subLevelIndex)}
                        placeholder='Select approvers'
                        formatGroupLabel={(data) => (
                          <Text fontWeight='bold' fontSize='sm'>
                            {data?.label}
                          </Text>
                        )}
                        formatOptionLabel={(option) => (
                          <Box>
                            <Text fontWeight='bold'>{option?.label}</Text>
                            {option?.email && (
                              <Text fontSize='xs' color='gray.500'>
                                {option?.email}
                              </Text>
                            )}
                          </Box>
                        )}
                      />
                    </Box>
                    {subLevelIndex > 0 && (
                      <IconButton
                        icon={<DeleteIcon />}
                        borderWidth={1}
                        size='xs'
                        variant='ghost'
                        onClick={() => removeApproverSequence(levelIndex, subLevelIndex)}
                        ml={2}
                        aria-label={`Remove approver sequence ${subLevelIndex + 1}`}
                      />
                    )}
                  </Flex>
                  {subLevelIndex < level?.sub_levels?.length - 1 && (
                    <Flex align='center' my={2}>
                      <Text mx={2} fontWeight='bold' fontSize='xs' bg='gray.100' px={2}>
                        OR
                      </Text>
                      <Divider flex={1} />
                    </Flex>
                  )}
                </Box>
              ))}
              <Button leftIcon={<AddIcon />} onClick={() => addApproverSequence(levelIndex)} variant='ghost' size='xs'>
                Add approver sequence
              </Button>
            </Box>
            {levelIndex > 0 && (
              <IconButton
                icon={<DeleteIcon />}
                size='xs'
                borderWidth={1}
                position='absolute'
                top={-2}
                right={-2}
                onClick={() => removeStep(levelIndex)}
                aria-label={`Remove step ${levelIndex + 1}`}
              />
            )}
          </Box>
        ))}

        <Button leftIcon={<BsLayers />} onClick={addStep} variant='outline' alignSelf='flex-start'>
          Add step
        </Button>

        <HStack spacing={4} justify='flex-end'>
          <Button variant='outline' onClick={goToPreviousStep}>
            Back
          </Button>
          <Button
            colorScheme='blue'
            onClick={submitPolicy}
            isLoading={isPolicyCreationLoading}
            isDisabled={isCreatePolicyDisabled}
          >
            Create Policy
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default AddApprovers;
