import { useEffect, useRef } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, Flex, Input, Text, Textarea } from '@chakra-ui/react';

const KycRfiQuestion = ({
  index,
  question: { prompt, transaction_id, is_document_required, is_front_back_required },
  updateQuestion,
  handleDeleteQuestion,
  isLastQuestion,
}) => {
  const questionRef = useRef(null);

  useEffect(() => {
    if (isLastQuestion) {
      questionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isLastQuestion]);

  return (
    <Box ref={questionRef} border='1px solid' borderColor='gray.200' p={4} borderRadius='md' background='white'>
      <Flex direction='column' gap={4}>
        <Flex gap={4} alignItems='center' justifyContent='space-between'>
          <Text>Question {index + 1}</Text>
          <Button
            onClick={() => handleDeleteQuestion(index)}
            variant='outline'
            colorScheme='red'
            size='sm'
            disabled={index === 0}
          >
            <DeleteIcon color='red.500' />
          </Button>
        </Flex>
        <Textarea
          placeholder='question'
          value={prompt}
          onChange={(e) => updateQuestion(index, 'prompt', e.target.value)}
        />
        <Input
          placeholder='transaction id  (optional)'
          value={transaction_id}
          onChange={(e) => updateQuestion(index, 'transaction_id', e.target.value)}
        />
        <Flex gap={4}>
          <Checkbox
            isChecked={is_document_required}
            onChange={() => updateQuestion(index, 'is_document_required', !is_document_required)}
          >
            is document required
          </Checkbox>
          <Checkbox
            isChecked={is_front_back_required}
            onChange={() => updateQuestion(index, 'is_front_back_required', !is_front_back_required)}
            disabled={!is_document_required}
          >
            is both-side required
          </Checkbox>
        </Flex>
      </Flex>
    </Box>
  );
};

export default KycRfiQuestion;
