import React, { useEffect, useMemo, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Center,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { getPendingCreditTransactions } from 'api/transactions';
import { TRANSACTION } from 'constants/transactionDetails';
import {
  CREDIT_TRANSACTION_LIMIT,
  CREDIT_TRANSACTION_STATUSES,
  CREDIT_TRANSACTION_TABLE_HEADINGS,
} from 'modules/credit-transactions/constants/constants';
import UnmappedTransactionModal from 'modules/credit-transactions/UnmappedTransactionModal';
import { formatTransactionStatus, formatTransactionTime } from 'modules/merchantinfo/utils/utils';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import Search from 'components/SearchBox/Search';
import TransactionScreeningDetailsDrawer from 'components/TransactionScreeningDetailsDrawer/TransactionScreeningDetailsDrawer';

export default function PendingCreditTransactions({ title = '', status = '' }) {
  const [creditTransactionsList, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const filteredCreditTransactionsList = useMemo(() => {
    if (!searchTerm) return creditTransactionsList;

    return creditTransactionsList.filter(
      (credit) =>
        credit?.id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        credit?.source_currency_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        credit?.beneficiary_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        credit?.beneficiary_account_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        credit?.source_account_address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        credit?.transfer_method?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [creditTransactionsList, searchTerm]);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (endIndex < filteredCreditTransactionsList?.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * CREDIT_TRANSACTION_LIMIT;
  const endIndex = startIndex + CREDIT_TRANSACTION_LIMIT;
  const visibleCreditTransactionsList = filteredCreditTransactionsList?.slice(startIndex, endIndex);

  const fetchPendingCreditTransactions = async () => {
    setIsLoading(true);
    try {
      const response = await getPendingCreditTransactions(status);

      setTransactions(response?.data?.data ?? []);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  const handleRowClick = (transaction) => {
    if (transaction?.status === CREDIT_TRANSACTION_STATUSES.UNMAPPED) {
      setSelectedTransaction(transaction);
      onOpen();
    } else if (transaction?.status === CREDIT_TRANSACTION_STATUSES.IN_REVIEW) {
      setSelectedTransaction(transaction);
      onOpen();
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filteredCreditTransactionsList]);

  useEffect(() => {
    fetchPendingCreditTransactions();
  }, []);

  const totalPages = Math.ceil(filteredCreditTransactionsList?.length / CREDIT_TRANSACTION_LIMIT);

  return (
    <Box overflowX='auto' maxWidth='100%'>
      <Heading as='h1' size='md' mb={3} mt={8}>
        {title}{' '}
        <Badge ml={1} colorScheme='blue' variant='solid' px={2}>
          {filteredCreditTransactionsList?.length}
        </Badge>
      </Heading>

      <Search searchType={TRANSACTION} setSearchTerm={setSearchTerm} />

      <LoadingErrorWrapper isLoading={isLoading} errorTitle='Error fetching credit transactions.' error={error}>
        <Box overflowX='auto' maxWidth='100%' style={{ borderWidth: '2px', borderRadius: '18px' }} mt={4}>
          <Table variant='simple'>
            <Thead>
              <Tr>
                {CREDIT_TRANSACTION_TABLE_HEADINGS?.map((heading, idx) => (
                  <Th key={idx}>{heading}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {visibleCreditTransactionsList?.map((transaction) => (
                <Tr
                  key={transaction?.id}
                  fontSize='sm'
                  cursor='pointer'
                  onClick={() => handleRowClick(transaction)}
                  _hover={{ background: 'gray.100' }}
                >
                  <Td>{formatTransactionTime(transaction?.created_at)}</Td>
                  <Td color='gray'>{transaction?.id ?? '-'}</Td>
                  <Td fontWeight='bold'>{transaction?.source_amount ?? '-'}</Td>
                  <Td color='gray' fontWeight='bold'>
                    {transaction?.source_currency_code ?? '-'}
                  </Td>
                  <Td color='gray'>{transaction?.beneficiary_name ?? '-'}</Td>
                  <Td color='gray'>{transaction?.beneficiary_account_number ?? '-'}</Td>
                  <Td color='gray'>{transaction?.source_account_address ?? '-'}</Td>
                  <Td color='gray'>{transaction?.transfer_method ?? '-'}</Td>
                  <Td fontWeight='bold'>{transaction?.transfer_type ?? '-'}</Td>
                  <Td>
                    <Badge colorScheme='gray' rounded='0.8em' px={3}>
                      {formatTransactionStatus(transaction?.status)}
                    </Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </LoadingErrorWrapper>

      {selectedTransaction?.status === CREDIT_TRANSACTION_STATUSES.UNMAPPED ? (
        <UnmappedTransactionModal
          isOpen={isOpen}
          onClose={onClose}
          transaction={selectedTransaction}
          refresh={fetchPendingCreditTransactions}
        />
      ) : (
        <TransactionScreeningDetailsDrawer isOpen={isOpen} onClose={onClose} transaction={selectedTransaction} />
      )}

      {!creditTransactionsList.length && !isLoading && !error && (
        <Center>
          <Text color='gray' mt={4}>
            No credit transactions found.
          </Text>
        </Center>
      )}

      {filteredCreditTransactionsList?.length ? (
        <Flex justify='space-between' align='center' mt={4}>
          <Text>
            Page {currentPage} of {totalPages}
          </Text>
          <Box>
            <IconButton icon={<ArrowBackIcon />} onClick={handlePreviousPage} disabled={currentPage === 1} />
            <IconButton
              ml={4}
              icon={<ArrowForwardIcon />}
              onClick={handleNextPage}
              disabled={endIndex >= filteredCreditTransactionsList?.length}
            />
          </Box>
        </Flex>
      ) : null}
    </Box>
  );
}
