import { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { getKycDetails } from 'api/kyc';
import { BY_ENTITY_ID } from 'constants/kyc';
import KycRfiDetails from 'components/KycRfi/KycRfiDetails';
import KycRfiForm from 'components/KycRfi/KycRfiForm';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';
import Search from 'components/SearchBox/Search';

export default function Kyc() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [kycDetails, setKycDetails] = useState(null);
  const [error, setError] = useState(null);

  const fetchKycDetails = async () => {
    try {
      setIsLoading(true);

      if (searchTerm) {
        const response = await getKycDetails(searchTerm);

        const formattedDetails = {
          ...response?.data?.data,
          rfi_requested: response?.data?.data?.rfi_requested?.map((item) => JSON.parse(item)),
          rfi_submitted: response?.data?.data?.rfi_submitted?.map((item) => JSON.parse(item)),
        };

        setKycDetails(formattedDetails);
      }
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchKycDetails();
  }, [searchTerm]);

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      p={4}
      width='100%'
      height={kycDetails ? '100%' : '100vh'}
    >
      <Text fontWeight={'bold'} fontSize={'3xl'} mb={4}>
        KYC RFI Questions
      </Text>

      <Search searchType={BY_ENTITY_ID} setSearchTerm={setSearchTerm} searchOnChangeEvent={false} width='40%' />
      <LoadingErrorWrapper isLoading={isLoading} error={error}>
        {kycDetails && (
          <Box
            border='1px solid'
            borderColor='gray.200'
            p={6}
            borderRadius='md'
            background='white'
            width='80%'
            maxWidth='600px'
            mt={6}
          >
            <Flex alignItems='center' mb={2}>
              <Text fontWeight='bold' mr={2}>
                Applicant Id:
              </Text>
              <Text>{kycDetails?.sumsub_applicant_id}</Text>
            </Flex>
            <Flex alignItems='center'>
              <Text fontWeight='bold' mr={2}>
                Entity Name:
              </Text>
              <Text>{kycDetails?.entity_name}</Text>
            </Flex>
          </Box>
        )}
      </LoadingErrorWrapper>

      {kycDetails && (
        <>
          <KycRfiForm entityId={searchTerm} fetchKycDetails={fetchKycDetails} />
          <KycRfiDetails kycDetails={kycDetails} />
        </>
      )}
    </Flex>
  );
}
