import { useContext, useEffect, useState } from 'react';
import { GoRocket } from 'react-icons/go';
import { Box, Button, FormControl, FormLabel, Heading, Select, Stack, Switch, useToast } from '@chakra-ui/react';
import { createPostOnboardingConfigs, getEntityDetailsById, getMerchantThirdPartyConfigs } from 'api/internaltools';
import { PostOnboardingContext } from 'modules/postonboarding/BankingPostOnboarding';
import { RISK_LEVEL_OPTIONS } from 'modules/postonboarding/constants/constants';
import { showError, showToast } from 'utils/notifications';

export default function MarkPostOnboardingSucceeded() {
  const { merchantId, entityId, kybId, nextStep, fiatPartner, cryptoPartner, fiatPartnerCustomerId } = useContext(
    PostOnboardingContext
  );
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [checkedThirdPartyConfigs, setCheckedThirdPartyConfigs] = useState({
    isThirdPartyFiatPayinsDisabled: false,
    isThirdPartyFiatPayoutsDisabled: false,
    isThirdPartyCryptoPayinsDisabled: false,
    isThirdPartyCryptoPayoutsDisabled: false,
  });
  const [initialThirdPartyConfigs, setInitialThirdPartyConfigs] = useState({
    isThirdPartyFiatPayinsDisabled: false,
    isThirdPartyFiatPayoutsDisabled: false,
    isThirdPartyCryptoPayinsDisabled: false,
    isThirdPartyCryptoPayoutsDisabled: false,
  });
  const [isEntityDetailsLoading, setIsEntityDetailsLoading] = useState(false);
  const [entityRiskLevel, setEntityRiskLevel] = useState('');

  const thirdPartyMerchantConfigs = [
    'isThirdPartyFiatPayinsDisabled',
    'isThirdPartyFiatPayoutsDisabled',
    'isThirdPartyCryptoPayinsDisabled',
    'isThirdPartyCryptoPayoutsDisabled',
  ];

  const handleCompletePostOnboardingClick = async () => {
    setIsLoading(true);
    try {
      const payload = {
        merchant_id: merchantId,
        entity_id: entityId,
        kyb_id: kybId,
        fiat_partner_code: fiatPartner,
        fiat_customer_id: fiatPartnerCustomerId,
        crypto_partner_code: cryptoPartner,
        is_third_party_fiat_payins_disabled: checkedThirdPartyConfigs?.isThirdPartyFiatPayinsDisabled,
        is_third_party_fiat_payouts_disabled: checkedThirdPartyConfigs?.isThirdPartyFiatPayoutsDisabled,
        is_third_party_crypto_payins_disabled: checkedThirdPartyConfigs?.isThirdPartyCryptoPayinsDisabled,
        is_third_party_crypto_payouts_disabled: checkedThirdPartyConfigs?.isThirdPartyCryptoPayoutsDisabled,
        risk_level: entityRiskLevel,
      };

      await createPostOnboardingConfigs(payload);

      showToast(toast, 'Success', 'Post onboarding completed successfully.', 'success');
      nextStep();
    } catch (error) {
      showError(toast, 'Error completing post onboarding.', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMerchantThirdPartyConfigs = async () => {
    setIsLoading(true);
    try {
      const response = await getMerchantThirdPartyConfigs(merchantId);
      const thirdPartyFlags = response?.data?.data || {};

      const updatedCheckedThirdPartyConfigs = { ...checkedThirdPartyConfigs };
      const updatedInitialThirdPartyConfigs = { ...initialThirdPartyConfigs };

      for (var flag in thirdPartyFlags) {
        if (Object.prototype.hasOwnProperty.call(thirdPartyFlags, flag) && thirdPartyMerchantConfigs?.includes(flag)) {
          updatedCheckedThirdPartyConfigs[flag] = thirdPartyFlags[flag] === true;
          updatedInitialThirdPartyConfigs[flag] = thirdPartyFlags[flag] === true;
        }
      }

      setCheckedThirdPartyConfigs(updatedCheckedThirdPartyConfigs);
      setInitialThirdPartyConfigs(updatedInitialThirdPartyConfigs);
    } catch (error) {
      showError(toast, 'Error fetching merchant third party configurations.', error);
    }
    setIsLoading(false);
  };

  const fetchEntityDetails = async () => {
    setIsEntityDetailsLoading(true);
    try {
      const response = await getEntityDetailsById(entityId);

      setEntityRiskLevel(response?.data?.data?.risk_level);
    } catch (e) {
      showError(toast, 'Error fetching entity details.', e);
    } finally {
      setIsEntityDetailsLoading(false);
    }
  };

  useEffect(() => {
    fetchMerchantThirdPartyConfigs();
    fetchEntityDetails();
  }, []);

  const handleSwitchChange = (key) => {
    setCheckedThirdPartyConfigs((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <Box>
      <Heading as='h2' size='md'>
        Final Post Onboarding Step
      </Heading>
      <Stack direction='row' spacing={4} mt={4}>
        {thirdPartyMerchantConfigs?.map((key) => (
          <Box key={key} borderWidth='1px' borderRadius='lg' p={4}>
            <FormControl display='flex' alignItems='center'>
              <FormLabel htmlFor={key} mb='0'>
                <i>Third Party</i> {key?.replace(/isThirdParty/, '').replace(/([A-Z])/g, ' $1')}?
              </FormLabel>
              <Switch
                id={key}
                isChecked={checkedThirdPartyConfigs[key]}
                isDisabled={initialThirdPartyConfigs[key]}
                onChange={() => handleSwitchChange(key)}
              />
            </FormControl>
          </Box>
        ))}
      </Stack>
      <Box w={400} mt={4}>
        <Heading size='md' mb={2}>
          Entity Risk Level
        </Heading>
        <Select
          placeholder='Select Risk Level'
          value={entityRiskLevel}
          onChange={(e) => setEntityRiskLevel(e.target.value)}
        >
          {RISK_LEVEL_OPTIONS.map((risk, idx) => (
            <option key={idx} value={risk.value}>
              {risk.label}
            </option>
          ))}
        </Select>
      </Box>

      <Button
        colorScheme='blue'
        onClick={handleCompletePostOnboardingClick}
        isLoading={isLoading || isEntityDetailsLoading}
        leftIcon={<GoRocket />}
        mt={8}
        disabled={isLoading || isEntityDetailsLoading || !entityRiskLevel}
      >
        Complete Post Onboarding
      </Button>
    </Box>
  );
}
