export const IN_REVIEW_CREDITS_TITLE = 'Credits In Review';

export const UNMAPPED_CREDITS_TITLE = 'Unmapped Credits';

export const CREDIT_TRANSACTION_LIMIT = 10;

export const CREDIT_TRANSACTION_TABLE_HEADINGS = [
  'Date',
  'Transaction ID',
  'Amount',
  'Currency',
  'Beneficiary Name',
  'Beneficiary Account No.',
  'Source Account Address',
  'Tranfer Method',
  'Transfer Type',
  'Status',
];

export const CREDIT_TRANSACTION_STATUSES = {
  IN_REVIEW: 'in_review',
  UNMAPPED: 'unmapped',
};

export const STATUS_COLORS = {
  succeeded: 'green',
  failed: 'red',
  in_review: 'yellow',
  unknown: 'gray',
};

export const ACTION_TYPES = {
  WHITELIST: 'whitelist',
  CHAINALYSIS: 'chainalysis',
};

export const ACTIONS = {
  APPROVE: 'approve',
  REJECT: 'reject',
};

export const TRANSACTION_TYPE_CREDIT = 'credit';
export const TRANSFER_METHOD_CRYPTO = 'CRYPTO';