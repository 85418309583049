import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { CREDIT_TRANSACTION_STATUSES, IN_REVIEW_CREDITS_TITLE, UNMAPPED_CREDITS_TITLE } from 'modules/credit-transactions/constants/constants';
import PendingCreditTransactions from 'modules/credit-transactions/PendingCreditTransactions';

function CreditTransactions() {
  return (
    <Box mt={6} mb={3}>
      <Tabs isFitted variant='enclosed-colored' isLazy>
        <TabList>
          <Tab>{IN_REVIEW_CREDITS_TITLE}</Tab>
          <Tab>{UNMAPPED_CREDITS_TITLE}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <PendingCreditTransactions 
            title={IN_REVIEW_CREDITS_TITLE} 
            status={CREDIT_TRANSACTION_STATUSES.IN_REVIEW} />
          </TabPanel>
          <TabPanel>
            <PendingCreditTransactions 
            title={UNMAPPED_CREDITS_TITLE} 
            status={CREDIT_TRANSACTION_STATUSES.UNMAPPED} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default CreditTransactions;
