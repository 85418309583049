import { useEffect, useState } from 'react';
import { Button, FormControl, FormLabel, Heading, Select, Textarea, useToast, VStack } from '@chakra-ui/react';
import { createAuditLog } from 'api/internaltools';
import UploadOwnerDocumentInput from 'modules/merchantinfo/components/UploadOwnerDocumentInput';
import { INPUT_FILE_FORMATS } from 'modules/merchantinfo/constants/constants';
import { RISK_LEVEL_OPTIONS } from 'modules/postonboarding/constants/constants';
import { showError, showToast } from 'utils/notifications';

const FORM_FIELDS = {
  NOTES: 'notes',
  SUPPORTING_DOCUMENT: 'supporting_document',
  RISK_LEVEL: 'risk_level',
};

const AuditLogForm = ({ oldRiskLevel, entityId, kybId, fetchAuditLogs }) => {
  const [formFields, setFormFields] = useState({
    notes: '',
    supporting_document_identifier: '',
    risk_level: {
      old_risk_level: oldRiskLevel,
      new_risk_level: '',
    },
  });
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploadSignedUrlResponse, setUploadSignedUrlResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleFormFieldsChange = (e) => {
    const { name, value } = e.target;

    if (name === FORM_FIELDS.RISK_LEVEL) {
      setFormFields({ ...formFields, risk_level: { ...formFields?.risk_level, new_risk_level: value } });

      return;
    }

    setFormFields({ ...formFields, [name]: value });
  };

  useEffect(() => {
    if (uploadSignedUrlResponse) {
      setFormFields({ ...formFields, supporting_document_identifier: uploadSignedUrlResponse?.identifier });
    }
  }, [uploadSignedUrlResponse]);

  const handleUploadDocument = async () => {
    if (!formFields?.notes) {
      showError(toast, 'Notes are required', 'Please provide notes for the update.');

      return;
    }

    if (formFields?.risk_level?.new_risk_level && !formFields?.notes) {
      showError(
        toast,
        'Notes are required for Risk Level changes',
        'Please provide notes when changing the Risk Level.'
      );

      return;
    }

    setLoading(true);
    try {
      await createAuditLog(formFields, entityId);

      const successMessage = formFields?.risk_level?.new_risk_level
        ? `Audit Log created successfully. Risk Level updated to ${formFields?.risk_level?.new_risk_level}.`
        : 'Audit Log created successfully.';

      showToast(toast, 'Success', successMessage, 'success');

      setTimeout(() => {
        showToast(toast, 'Info', 'Please click on Proceed to see the updated logs.', 'info');
      }, 5000);
    } catch (error) {
      showError(toast, 'Error during submission', error?.message || 'An unexpected error occurred');
    } finally {
      fetchAuditLogs();
      setLoading(false);
    }
  };

  return (
    <VStack
      w={{ base: '100%', md: '50%' }}
      borderRadius={'md'}
      border={'1px solid'}
      borderColor={'gray.200'}
      p={3}
      backgroundColor={'gray.100'}
      height='full'
      spacing={4}
      alignItems='stretch'
      overflow={'auto'}
    >
      <Heading size='sm'>Update Entity</Heading>
      <FormControl>
        <FormLabel color={'gray.500'} fontWeight={'bold'}>
          Notes
        </FormLabel>
        <Textarea
          value={formFields?.notes}
          onChange={handleFormFieldsChange}
          name={FORM_FIELDS.NOTES}
          placeholder='Enter Notes'
          backgroundColor={'white'}
          disabled={loading}
          minH={'40px'}
          maxH={'100px'}
        />
      </FormControl>

      <FormControl>
        <FormLabel color={'gray.500'} fontWeight={'bold'}>
          Supporting Document
        </FormLabel>
        <UploadOwnerDocumentInput
          merchantId={entityId}
          kybId={kybId}
          selectedDocumentType={'supporting_document'}
          isBusinessDocument={false}
          selectedOwnerIndex={0}
          fileToUpload={fileToUpload}
          setFileToUpload={setFileToUpload}
          uploadSignedUrlResponse={uploadSignedUrlResponse}
          setUploadSignedUrlResponse={setUploadSignedUrlResponse}
          acceptedFormats={`${INPUT_FILE_FORMATS.PNG}, ${INPUT_FILE_FORMATS.JPEG}, ${INPUT_FILE_FORMATS.JPG}, ${INPUT_FILE_FORMATS.PDF}, ${INPUT_FILE_FORMATS.BMP}`}
        />
      </FormControl>

      <FormControl>
        <FormLabel color={'gray.500'} fontWeight={'bold'}>
          Entity Risk Level Category
        </FormLabel>
        <Select
          value={formFields?.risk_level.new_risk_level}
          name={FORM_FIELDS.RISK_LEVEL}
          onChange={handleFormFieldsChange}
          backgroundColor={'white'}
          placeholder='Select Entity Category'
          disabled={loading}
          color={'gray.500'}
        >
          {RISK_LEVEL_OPTIONS.filter((risk) => risk.value != oldRiskLevel).map((risk, idx) => (
            <option key={idx} value={risk.value}>
              {risk.label}
            </option>
          ))}
        </Select>
      </FormControl>

      <Button
        alignSelf={'flex-start'}
        background={'blue.500'}
        color={'white'}
        height={'40px'}
        onClick={handleUploadDocument}
        _hover={{ background: 'blue.600' }}
        isLoading={loading}
        flexShrink={0}
        disabled={loading || !formFields?.notes}
      >
        Submit
      </Button>
    </VStack>
  );
};

export default AuditLogForm;
