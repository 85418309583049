import { Box, VStack } from '@chakra-ui/react';
import AuditLogCard from 'modules/audit-logs/components/AuditLogCard';

const AuditLogDetails = ({ auditLogs }) => {
  return (
    <VStack
      w={{ base: '100%', md: '50%' }}
      height='full'
      borderRadius={'md'}
      backgroundColor={'gray.100'}
      p={3}
      border={'1px solid'}
      borderColor={'gray.200'}
      spacing={4}
      overflowY={'auto'}
    >
      {auditLogs?.length !== 0 &&
        auditLogs?.map((log) => (
          <AuditLogCard
            key={log?.id}
            auditLogId={log?.id}
            date={log?.date}
            email={log?.email}
            resources={log?.resources}
          />
        ))}
      {auditLogs?.length === 0 && (
        <Box
          fontSize={'2xl'}
          fontWeight={'bold'}
          color={'gray.500'}
          textAlign={'center'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'full'}
        >
          No audit logs found
        </Box>
      )}
    </VStack>
  );
};

export default AuditLogDetails;
