import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { getBeneficiariesByMerchantId, refundCreditTransaction } from 'api/transactions';
import CreditTransactionDetails from 'modules/credit-transactions/CreditTransactionDetails';
import { showError, showToast } from 'utils/notifications';

export default function RefundCreditTransactionModal({ isOpen, onClose, transaction, partnerCode }) {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBene, setSelectedBene] = useState(null);
  const [refundType, setRefundType] = useState('existing');

  const [beneAccountNumber, setBeneAccountNumber] = useState('');
  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState('');

  const toast = useToast();

  const fetchBeneficiaries = async () => {
    if (!transaction?.parent_id) return;
    setIsLoading(true);
    try {
      const response = await getBeneficiariesByMerchantId(transaction?.parent_id, transaction?.transfer_method);

      setBeneficiaries(response?.data?.data || []);
    } catch (error) {
      showError(toast, 'Error fetching beneficiaries', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchBeneficiaries();
    }
  }, [isOpen, transaction?.parent_id]);

  useEffect(() => {
    setSelectedBene(null);
    setBeneAccountNumber('');
    setCurrency('');
    setAmount('');
  }, [refundType]);

  const handleRefund = async () => {
    setIsLoading(true);
    try {
      const payload =
        refundType === 'existing'
          ? {
              beneficiary_id: selectedBene?.id,
              currency,
              amount: parseFloat(amount),
            }
          : {
              is_create_new_beneficiary: true,
              beneficiary_account_number: beneAccountNumber,
              currency,
              amount: parseFloat(amount),
            };

      await refundCreditTransaction(transaction?.id, payload);
      showToast(toast, 'Refund initiated successfully', '');
      onClose();
    } catch (error) {
      showError(toast, 'Error initiating refund', error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedBene(null);
    setRefundType('existing');
    setBeneAccountNumber('');
    setCurrency('');
    setAmount('');
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='xl'>
      <ModalOverlay />
      <ModalContent maxH='90vh'>
        <ModalHeader>Refund credit</ModalHeader>
        <ModalBody overflow='auto'>
          <CreditTransactionDetails transaction={transaction} partnerCode={partnerCode} />
          <RadioGroup onChange={setRefundType} value={refundType} mb={6}>
            <Stack>
              <Radio value='existing'>Select existing beneficiary</Radio>
              <Radio value='new'>Add new beneficiary</Radio>
            </Stack>
          </RadioGroup>

          {refundType === 'existing' && (
            <>
              <Text mb={4} fontWeight='medium'>
                Select a beneficiary
              </Text>
              <Box maxH='300px' overflowY='auto' mb={6}>
                <Stack spacing={3}>
                  {beneficiaries?.map((bene) => (
                    <Box
                      key={bene?.id}
                      p={3}
                      borderWidth='1px'
                      borderRadius='md'
                      cursor='pointer'
                      bg={selectedBene?.id === bene?.id ? 'blue.50' : 'white'}
                      onClick={() => setSelectedBene(bene)}
                      _hover={{ borderColor: 'blue.500' }}
                    >
                      <Text fontWeight='medium'>{bene.beneficiary_name}</Text>
                      <Text fontSize='sm' color='gray.600'>
                        {bene?.beneficiary_account_number}
                      </Text>
                    </Box>
                  ))}
                </Stack>
                {beneficiaries?.length === 0 && <Text color="gray">No beneficiaries found.</Text>}
              </Box>
            </>
          )}

          {refundType === 'new' && (
            <FormControl mb={4}>
              <FormLabel>Source Account Address</FormLabel>
              <Input
                value={beneAccountNumber}
                onChange={(e) => setBeneAccountNumber(e.target.value)}
                placeholder='Enter source account address'
              />
            </FormControl>
          )}

          {(selectedBene || refundType === 'new') && (
            <>
              <FormControl mb={4}>
                <FormLabel>Currency</FormLabel>
                <Input value={currency} onChange={(e) => setCurrency(e.target.value)} placeholder='Enter currency' />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Amount</FormLabel>
                <Input
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  type='number'
                  placeholder='Enter amount'
                />
              </FormControl>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            colorScheme='blue'
            ml={3}
            onClick={handleRefund}
            isLoading={isLoading}
            isDisabled={
              (refundType === 'existing' && !selectedBene) ||
              (refundType === 'new' && !beneAccountNumber) ||
              !currency ||
              !amount
            }
          >
            Refund
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
