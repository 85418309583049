import { useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { getAuditLogsByEntityId } from 'api/internaltools';
import AuditLogDetails from 'modules/audit-logs/components/AuditLogDetails';
import AuditLogForm from 'modules/audit-logs/components/AuditLogForm';
import AuditLogInfo from 'modules/audit-logs/components/AuditLogInfo';
import LoadingErrorWrapper from 'components/LoadingErrorWrapper/LoadingErrorWrapper';

const AuditLogs = () => {
  const [entityId, setEntityId] = useState('');
  const [isAuditLogsLoading, setIsAuditLogsLoading] = useState(false);
  const [auditLogs, setAuditLogs] = useState(null);
  const [error, setError] = useState(null);

  const fetchAuditLogs = async () => {
    setIsAuditLogsLoading(true);
    setError(null);
    try {
      const response = await getAuditLogsByEntityId(entityId);

      setAuditLogs(response.data.data);
    } catch (error) {
      setError(error?.response?.data?.error?.message || error?.message);
    } finally {
      setIsAuditLogsLoading(false);
    }
  };

  return (
    <Container maxW='container.xl' py={8}>
      <HStack justify='space-between' mb={3}>
        <Heading as='h1' size='md'>
          Audit Logs
        </Heading>
      </HStack>
      <VStack spacing={4} align='stretch'>
        <Grid templateColumns='repeat(2, 1fr)' gap={4}>
          <GridItem>
            <FormControl>
              <FormLabel>Entity ID</FormLabel>
              <Input
                value={entityId}
                onChange={(e) => setEntityId(e.target.value)}
                placeholder='Enter Entity ID'
                isDisabled={isAuditLogsLoading}
                onKeyDown={(e) => e.key === 'Enter' && entityId && fetchAuditLogs()}
              />
            </FormControl>
          </GridItem>
          <GridItem alignSelf='end'>
            <Button
              colorScheme='blue'
              onClick={fetchAuditLogs}
              isLoading={isAuditLogsLoading}
              isDisabled={!entityId || isAuditLogsLoading}
              w='fit-content'
            >
              Proceed
            </Button>
          </GridItem>
        </Grid>
      </VStack>
      <LoadingErrorWrapper isLoading={isAuditLogsLoading} error={error} errorTitle={error}>
        {auditLogs && (
          <>
            <AuditLogInfo auditLogs={auditLogs} />
            <Stack
              gap={2}
              direction={{ base: 'column', md: 'row' }}
              width='100%'
              height={{
                base: 'auto',
                md: '62dvh',
              }}
              overflow={{
                base: 'auto',
                md: 'hidden',
              }}
              mt={4}
            >
              <AuditLogForm
                oldRiskLevel={auditLogs?.risk_level}
                entityId={auditLogs?.entity_id}
                kybId={auditLogs?.kyb_id}
                fetchAuditLogs={fetchAuditLogs}
              />
              <AuditLogDetails auditLogs={auditLogs?.audit_logs} />
            </Stack>
          </>
        )}
      </LoadingErrorWrapper>
    </Container>
  );
};

export default AuditLogs;
