import { Checkbox, Flex, Text } from '@chakra-ui/react';
import KycRfiDocumentInfo from 'components/KycRfi/KycRfiDocumentInfo';

const KycRfiCard = ({ rfi }) => {
  const {
    prompt_format: { prompt, transaction_id, is_document_required, is_front_back_required },
    prompt: { notes, document },
  } = rfi;

  return (
    <Flex
      flexDirection={'column'}
      gap={3}
      border={'1px solid'}
      borderColor={'gray.200'}
      p={4}
      borderRadius={'md'}
      background={'white'}
      alignItems={'start'}
      justifyItems={'center'}
      width={'100%'}
    >
      <Flex gap={2}>
        <Text>Question:</Text>
        <Text>{prompt}</Text>
      </Flex>

      {transaction_id && (
        <Flex gap={2}>
          <Text>Transaction Id:</Text>
          <Text>{transaction_id}</Text>
        </Flex>
      )}

      {document?.map((doc, index) => (
        <KycRfiDocumentInfo document={doc} key={index} />
      ))}

      {notes && (
        <Flex gap={2}>
          <Text>Notes:</Text>
          <Text>{notes}</Text>
        </Flex>
      )}

      <Flex gap={4}>
        <Checkbox isChecked={is_document_required} disabled={true}>
          is document required
        </Checkbox>
        <Checkbox isChecked={is_front_back_required} disabled={true}>
          is both-side required
        </Checkbox>
      </Flex>
    </Flex>
  );
};

export default KycRfiCard;
