import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export default function CreditTransactionDetails({ transaction, partnerCode }) {
  return (
    <Box mb={4}>
      <Text>Transaction Id - {transaction?.id || '-'}</Text>
      <Text>Partner Code - {partnerCode || '-'}</Text>
      {transaction?.beneficiary_account_number && (
        <Text>Source account no. - {transaction?.beneficiary_account_number}</Text>
      )}
      {transaction?.source_account_address && (
        <Text>Source account address - {transaction?.source_account_address}</Text>
      )}
    </Box>
  );
}
