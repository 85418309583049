import { Flex } from '@chakra-ui/react';
import RfiSection from 'components/KycRfi/KycRfiSection';

const KycRfiDetails = ({ kycDetails }) => {
  return (
    <Flex direction='row' alignContent={'center'} justifyContent={'center'} gap={4} width='100%' height='100%' mt={8}>
      <RfiSection
        title="Requested RFI's"
        rfiList={kycDetails?.rfi_requested}
        emptyMessage="No RFI Request's available"
      />
      <RfiSection
        title="Submitted RFI's"
        rfiList={kycDetails?.rfi_submitted}
        emptyMessage="No RFI Submission's available"
      />
    </Flex>
  );
};

export default KycRfiDetails;
