import React, { useState } from 'react';
import { Avatar, Box, Button, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
// import { getUserTransactionLimit } from 'api/transactions';
import { useAuthentication } from 'AuthContext';
// import { TransactionFrequencyMappings } from 'modules/internalusers/constants/constants';
import RoleEditDrawer from 'modules/internalusers/RoleEditDrawer';
import TransactionLimitEditDrawer from 'modules/internalusers/TransactionLimitEditDrawer';
// import { showError } from 'utils/notifications';

export default function InternalUserRow(props) {
  const { userId, firstName, lastName, emailId, userRole } = props;
  const [currentRole, setCurrentRole] = useState(userRole);
  const [txnLimit, setTxnLimit] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [isTransactionLimitLoading, setIsTransactionLimitLoading] = useState(false);
  const [isTxnLimitDrawerOpen, setIsTxnLimitDrawerOpen] = useState(false);
  // const toast = useToast();

  const { user } = useAuthentication();

  // const getInternalUserTxnLimit = async () => {
  //   setIsTransactionLimitLoading(true);
  //   try {
  //     const response = await getUserTransactionLimit(userId);

  //     setTxnLimit(response?.data?.data);
  //   } catch (error) {
  //     showError(toast, 'Error fetching transaction limit', error);
  //   } finally {
  //     setIsTransactionLimitLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getInternalUserTxnLimit();
  // }, []);

  return (
    <>
      <Tr>
        <Td pl='0px'>
          <Box display='flex' alignItems='center'>
            <Avatar name={firstName + ' ' + lastName} size='sm' />
            <Box ml='3'>
              <Text fontSize='sm' fontWeight='bold'>
                {firstName + ' ' + lastName}
                {userId === user?.id && ' (You)'}
              </Text>
              <Text fontSize='sm' color='gray'>
                {emailId}
              </Text>
            </Box>
          </Box>
        </Td>
        <Td>
          <Text fontSize='sm' fontWeight='bold'>
            {currentRole?.name}
          </Text>
        </Td>
        <Td>
          {/* {txnLimit?.frequency_type !== '' ? (
            <Text fontSize='sm'>
              ${txnLimit?.upper_limit} / {TransactionFrequencyMappings[txnLimit?.frequency_type]}
            </Text>
          ) : (
            <Text fontSize='sm' color="gray">not set</Text>
          )} */}
        </Td>
        <Td>
          {userId !== user?.id && (
            <Button fontSize='sm' onClick={onOpen}>
              {Object.keys(currentRole)?.length === 0 ? 'Assign role' : 'Edit role'}
            </Button>
          )}
        </Td>
        <Td>
          {/* {!(userId === user?.id) && (
            <Button fontSize='sm' isDisabled={isTransactionLimitLoading} onClick={() => setIsTxnLimitDrawerOpen(true)}>
              Edit txn limit
            </Button>
          )} */}
        </Td>
      </Tr>

      {isOpen && (
        <RoleEditDrawer
          isOpen={isOpen}
          onClose={onClose}
          user={{ userId, firstName, lastName, email: emailId, currentRole }}
          setCurrentRole={setCurrentRole}
        />
      )}

      {isTxnLimitDrawerOpen && (
        <TransactionLimitEditDrawer
          isOpen={isTxnLimitDrawerOpen}
          onClose={() => setIsTxnLimitDrawerOpen(false)}
          user={{ userId, firstName, lastName, email: emailId }}
          txnLimit={txnLimit}
          setTxnLimit={setTxnLimit}
        />
      )}
    </>
  );
}
