import { useState } from 'react';
import { Button, Flex, useToast } from '@chakra-ui/react';
import { updateKycDetails } from 'api/kyc';
import { showError } from 'utils/notifications';
import KycRfiQuestion from 'components/KycRfi/KycRfiQuestion';

const KycRfiForm = ({ entityId, fetchKycDetails }) => {
  const [questions, setQuestions] = useState([
    {
      prompt: '',
      is_document_required: false,
      is_front_back_required: false,
      transaction_id: '',
    },
  ]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleAddNewQuestion = () => {
    setQuestions((prev) => [...prev, { prompt: '', is_document_req: false, is_both_side_req: false }]);
  };

  const updateQuestion = (index, key, value) => {
    setQuestions((prev) => prev.map((question, i) => (i === index ? { ...question, [key]: value } : question)));
  };

  const handleDeleteQuestion = (index) => {
    if (questions.length === 1) return;
    setQuestions((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const hasEmptyPrompt = questions.some((question) => !question.prompt.trim());

    if (hasEmptyPrompt) {
      showError(toast, 'Question prompt cannot be empty', 'Please fill all the questions');

      return;
    }

    setLoading(true);
    try {
      await updateKycDetails(entityId, questions);
      setQuestions([{ prompt: '', is_document_required: false, is_front_back_required: false, transaction_id: '' }]);
      fetchKycDetails();
    } catch (error) {
      showError(toast, 'Error during submission', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      direction='column'
      gap={4}
      pt={8}
      px={8}
      width={'100%'}
      border={'1px solid'}
      borderRadius={'md'}
      borderColor={'gray.400'}
      mt={8}
      height={'375px'}
    >
      <Flex direction={'column'} gap={4} width={'100%'} overflow={'auto'}>
        {questions?.map((question, index) => (
          <KycRfiQuestion
            key={index}
            question={question}
            updateQuestion={updateQuestion}
            index={index}
            handleDeleteQuestion={handleDeleteQuestion}
            isLastQuestion={index === questions?.length - 1}
          />
        ))}
      </Flex>
      <Flex
        flexDirection={'row'}
        gap={4}
        justifyContent={'space-between'}
        width={'100%'}
        position={'sticky'}
        bottom={0}
        pb={4}
      >
        <Button onClick={handleAddNewQuestion} width='fit-content'>
          + Add new question
        </Button>
        <Button
          onClick={handleSubmit}
          width='fit-content'
          color='white'
          background='black'
          isLoading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export default KycRfiForm;
