import { Box, Flex, Text } from '@chakra-ui/react';

const KycRfiDocumentInfo = ({ document }) => {
  const { document_type, document_base64, document_sub_type, issued_date, number } = document;

  return (
    <Flex
      direction='column'
      gap={2}
      p={4}
      border='1px solid'
      borderColor='gray.300'
      borderRadius='md'
      backgroundColor='gray.50'
      boxShadow='sm'
      fontSize='sm'
    >
      <Flex direction='row' align='center' wrap='wrap' gap={4}>
        {document_base64 && (
          <Box>
            <Text as='span' fontWeight='medium' color='gray.600'>
              URL:
            </Text>
            <Text as='span' color='blue.500' ml={1} wordBreak='break-word'>
              {document_base64}
            </Text>
          </Box>
        )}

        {document_type && (
          <Box>
            <Text as='span' fontWeight='medium' color='gray.600'>
              Type:
            </Text>
            <Text as='span' color='gray.800' ml={1}>
              {document_type}
            </Text>
          </Box>
        )}

        {document_sub_type && (
          <Box>
            <Text as='span' fontWeight='medium' color='gray.600'>
              Sub-type:
            </Text>
            <Text as='span' color='gray.800' ml={1}>
              {document_sub_type}
            </Text>
          </Box>
        )}

        {issued_date && (
          <Box>
            <Text as='span' fontWeight='medium' color='gray.600'>
              Issued Date:
            </Text>
            <Text as='span' color='gray.800' ml={1}>
              {issued_date}
            </Text>
          </Box>
        )}

        {number && (
          <Box>
            <Text as='span' fontWeight='medium' color='gray.600'>
              Number:
            </Text>
            <Text as='span' color='gray.800' ml={1}>
              {number}
            </Text>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default KycRfiDocumentInfo;
